// src/manholeData.js

export const manholeCovers = [
    {
        id: 1,
        image: require('./assets/manhole1.jpg'),
        location: 'The Hague, The Netherlands',
        latitude: "52° 4' 48,49\" N",
        longitude: "4° 18' 53,52\" E"
    },
    {
        id: 2,
        image: require('./assets/manhole2.jpg'),
        location: 'Bruges, Belgium',
        latitude: "51° 12' 16,632\" N",
        longitude: "3° 13' 12,312\" E"
    },
    {
        id: 3,
        image: require('./assets/manhole3.jpg'),
        location: 'Bruges, Belgium',
        latitude: "51° 12' 16,128\" N",
        longitude: "3° 13' 11,442\" E"
    },
    {
        id: 4,
        image: require('./assets/manhole4.jpg'),
        location: 'Moscow, Russia',
        latitude: "55° 45' 7,638\" N",
        longitude: "37° 37' 48,9\" E"
    },
    {
        id: 5,
        image: require('./assets/manhole5.jpg'),
        location: 'Belgrade, Serbia',
        latitude: "44° 49' 14,37\" N",
        longitude: "20° 27' 13,29\" E"
    },
    {
        id: 6,
        image: require('./assets/manhole6.jpg'),
        location: 'Diyarbakir, Türkiye',
        latitude: "37° 18' 47,322\" N",
        longitude: "40° 43' 57,012\" E"
    },
    {
        id: 7,
        image: require('./assets/manhole7.jpg'),
        location: 'Istanbul, Türkiye',
        latitude: "40° 59' 5,442\" N",
        longitude: "29° 1' 32,7\" E"
    },
    {
        id: 8,
        image: require('./assets/manhole8.jpg'),
        location: 'Moscow, Russia',
        latitude: "55° 39' 43,002\" N",
        longitude: "37° 24' 41,49\" E"
    },
    {
        id: 9,
        image: require('./assets/manhole9.jpg'),
        location: 'Weinheim, Germany',
        latitude: "49° 32' 47,568\" N",
        longitude: "8° 40' 22,152\" E"
    },
    {
        id: 10,
        image: require('./assets/manhole10.jpg'),
        location: 'Mainz, Germany',
        latitude: "49° 59' 49,38\" N",
        longitude: "8° 16' 24,378\" E"
    },
    {
        id: 11,
        image: require('./assets/manhole11.jpg'),
        location: 'Kaliningrad, Russia',
        latitude: "54° 42' 23,202\" N",
        longitude: "20° 30' 45,3\" E"
    },
    {
        id: 12,
        image: require('./assets/manhole12.jpg'),
        location: 'Zelenogradsk, Russia',
        latitude: "54° 57' 37,812\" N",
        longitude: "20° 28' 38,652\" E"
    },
    {
        id: 13,
        image: require('./assets/manhole13.jpg'),
        location: 'Svetlogorsk, Russia',
        latitude: "54° 56' 17,268\" N",
        longitude: "20° 9' 38,22\" E"
    },
    {
        id: 14,
        image: require('./assets/manhole14.jpg'),
        location: 'Sovietsk, Russia',
        latitude: "55° 5' 5,06\" N",
        longitude: "21° 53' 53,76\" E"
    },
    {
        id: 15,
        image: require('./assets/manhole15.jpg'),
        location: 'Chernyakhovsk, Russia',
        latitude: "54° 38' 5,772\" N",
        longitude: "21° 48' 33,63\" E"
    },
    {
        id: 16,
        image: require('./assets/manhole16.jpg'),
        location: 'Budapest, Hungary',
        latitude: "47° 29' 47,082\" N",
        longitude: "19° 3' 28,06\" E"
    },
    {
        id: 17,
        image: require('./assets/manhole17.jpg'),
        location: 'Budapest, Hungary',
        latitude: "47° 29' 48,78\" N",
        longitude: "19° 3' 10,61\" E"
    },
    {
        id: 18,
        image: require('./assets/manhole18.jpg'),
        location: 'Mochimune, Japan',
        latitude: "34° 55' 16,332\" N",
        longitude: "138° 22' 2,592\" E"
    },
    {
        id: 19,
        image: require('./assets/manhole19.jpg'),
        location: 'Mochimune, Japan',
        latitude: "34° 55' 16,128\" N",
        longitude: "138° 22' 2,478\" E"
    },
    {
        id: 20,
        image: require('./assets/manhole20.jpg'),
        location: 'Fujikawaguchiko, Japan',
        latitude: "35° 30' 32,142\" N",
        longitude: "138° 45' 41,25\" E"
    },
    {
        id: 21,
        image: require('./assets/manhole21.jpg'),
        location: 'Mochimune, Japan',
        latitude: "34° 55' 11,772\" N",
        longitude: "138° 21' 54,24\" E"
    },
    {
        id: 22,
        image: require('./assets/manhole22.jpg'),
        location: 'Shizuoka, Japan',
        latitude: "34° 58' 16,548\" N",
        longitude: "138° 23' 12,96\" E"
    },
    {
        id: 23,
        image: require('./assets/manhole23.jpg'),
        location: 'Shizuoka, Japan',
        latitude: "34° 58' 16,578\" N",
        longitude: "138° 23' 13,338\" E"
    },
    {
        id: 24,
        image: require('./assets/manhole24.jpg'),
        location: 'Shizuoka, Japan',
        latitude: "34° 58' 26,25\" N",
        longitude: "138° 23' 11,088\" E"
    },
    {
        id: 25,
        image: require('./assets/manhole25.jpg'),
        location: 'Osaka, Japan',
        latitude: "34° 41' 23,532\" N",
        longitude: "135° 30' 58,5\" E"
    },
    {
        id: 26,
        image: require('./assets/manhole26.jpg'),
        location: 'Osaka, Japan',
        latitude: "34° 41' 18,978\" N",
        longitude: "135° 31' 3,228\" E"
    },
    {
        id: 27,
        image: require('./assets/manhole27.jpg'),
        location: 'Osaka, Japan',
        latitude: "34° 41' 8,49\" N",
        longitude: "135° 31' 7,242\" E"
    },
    {
        id: 28,
        image: require('./assets/manhole28.jpg'),
        location: 'Osaka, Japan',
        latitude: "34° 41' 6,282\" N",
        longitude: "135° 31' 18,612\" E"
    },
    {
        id: 29,
        image: require('./assets/manhole29.jpg'),
        location: 'Kotor, Montenegro',
        latitude: "42° 25' 19,89\" N",
        longitude: "18° 46' 16,62\" E"
    },
    {
        id: 30,
        image: require('./assets/manhole30.jpg'),
        location: 'Nara, Japan',
        latitude: "34° 41' 3,552\" N",
        longitude: "135° 49' 43,722\" E"
    },
    // Add more manhole covers here
  ];
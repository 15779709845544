import React, { useState, useEffect, useCallback } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThumbsUp, ThumbsDown, MapPin, X, Trophy } from 'lucide-react';
import { manholeCovers } from './manholeData';
import { motion, AnimatePresence } from 'framer-motion';

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [liked, setLiked] = useState([]);
  const [disliked, setDisliked] = useState([]);
  const [history, setHistory] = useState([]);
  const [showPrompt, setShowPrompt] = useState(true);
  const [randomizedCovers, setRandomizedCovers] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [ratings, setRatings] = useState({});
  const [currentManhole, setCurrentManhole] = useState(null);
  const [selectedManhole, setSelectedManhole] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
const [touchEnd, setTouchEnd] = useState(null);

  useEffect(() => {
    const shuffled = [...manholeCovers].sort(() => Math.random() - 0.5);
    setRandomizedCovers(shuffled);
    
    // Load ratings from localStorage
    const storedRatings = localStorage.getItem('manholeRatings');
    if (storedRatings) {
      setRatings(JSON.parse(storedRatings));
    }
  }, []);

  useEffect(() => {
    if (randomizedCovers.length > 0 && currentIndex < randomizedCovers.length) {
      setCurrentManhole(randomizedCovers[currentIndex]);
    }
  }, [currentIndex, randomizedCovers]);

  const handleSwipe = useCallback((direction) => {
    setHistory([...history, { index: currentIndex, action: direction }]);
    const manholeId = currentManhole.id;
    
    const newRatings = { ...ratings };
    if (!newRatings[manholeId]) {
      newRatings[manholeId] = { likes: 0, dislikes: 0 };
    }
    
    if (direction === 'right') {
      setLiked([...liked, manholeId]);
      newRatings[manholeId].likes += 1;
    } else {
      setDisliked([...disliked, manholeId]);
      newRatings[manholeId].dislikes += 1;
    }
    
    setRatings(newRatings);
    localStorage.setItem('manholeRatings', JSON.stringify(newRatings));
    
    setSwipeDirection(direction);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setSwipeDirection(null);
    }, 300);
    setShowPrompt(false);
  }, [currentIndex, currentManhole, history, liked, disliked, ratings]);

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe || isRightSwipe) {
      handleSwipe(isLeftSwipe ? 'left' : 'right');
    }
  };

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'ArrowLeft') {
      handleSwipe('left');
    } else if (e.key === 'ArrowRight') {
      handleSwipe('right');
    }
  }, [handleSwipe]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const LeaderboardTable = () => {
    const sortedCovers = manholeCovers.sort((a, b) => 
      (ratings[b.id]?.likes || 0) - (ratings[a.id]?.likes || 0)
    );

    return (
      <div style={{
        backgroundColor: '#2a2a2a',
        borderRadius: '0.75rem',
        padding: '1rem',
        overflowY: 'auto',
        maxHeight: '80vh'
      }}>
        <h2 style={{ color: 'white', textAlign: 'center' }}>Manhole Cover Leaderboard</h2>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid white' }}>
              <th style={{ color: 'white', padding: '0.5rem' }}>Rank</th>
              <th style={{ color: 'white', padding: '0.5rem' }}>Cover</th>
              <th style={{ color: 'white', padding: '0.5rem' }}>Location</th>
              <th style={{ color: 'white', padding: '0.5rem' }}>Likes</th>
              <th style={{ color: 'white', padding: '0.5rem' }}>Dislikes</th>
            </tr>
          </thead>
          <tbody>
            {sortedCovers.map((cover, index) => (
              <tr key={cover.id} style={{ borderBottom: '1px solid #444' }}>
                <td style={{ color: 'white', padding: '0.5rem', textAlign: 'center' }}>{index + 1}</td>
                <td style={{ padding: '0.5rem' }}>
                  <img src={cover.image} alt={cover.location} style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} />
                </td>
                <td style={{ color: 'white', padding: '0.5rem' }}>
                <button 
                    onClick={() => openMap(cover)} 
                    style={{ 
                      color: 'white', 
                      background: 'none', 
                      border: 'none', 
                      cursor: 'pointer', 
                      textDecoration: 'underline' 
                    }}
                  >
                    {cover.location}
                  </button>
                </td>
                <td style={{ color: 'white', padding: '0.5rem', textAlign: 'center' }}>{ratings[cover.id]?.likes || 0}</td>
                <td style={{ color: 'white', padding: '0.5rem', textAlign: 'center' }}>{ratings[cover.id]?.dislikes || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const openMap = (manhole) => {
    setSelectedManhole(manhole);
    setShowMap(true);
  };

  const closeMap = () => {
    setShowMap(false);
    setSelectedManhole(null);
  };

  const allViewedMessage = (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#2a2a2a',
      borderRadius: '0.75rem',
      padding: '1rem',
      boxSizing: 'border-box',
      textAlign: 'center'
    }}>
      <p style={{ fontSize: '1.25rem', fontWeight: '600', color: 'white' }}>
        All manhole covers have been viewed.
      </p>
      <p style={{ fontSize: '1rem', color: 'white', marginTop: '1rem' }}>
        Visit the leaderboard to see the rankings of all manhole covers!
      </p>
      <button
        onClick={() => setShowLeaderboard(true)}
        style={{
          marginTop: '1rem',
          padding: '0.5rem 1rem',
          backgroundColor: '#1a1a1a',
          color: 'white',
          border: 'white 1px solid',
          borderRadius: '0.45rem',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem'
        }}
      >
        <Trophy size={20} />
        View Leaderboard
      </button>
    </div>
  );

  const buttonStyle = {
    padding: '0.5rem',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    gap: '0.5rem',
    flex: 1,
    height: '50px',
  };

  const convertCoordinates = (latStr, lonStr) => {
    const parseDMS = (str) => {
      const parts = str.split(/[^\d\w]+/);
      const deg = parseFloat(parts[0]);
      const min = parseFloat(parts[1]);
      const sec = parseFloat(parts[2].replace(',', '.'));
      return deg + min / 60 + sec / 3600;
    };

    const lat = parseDMS(latStr);
    const lon = parseDMS(lonStr);

    return { lat, lon };
  };

  return (
    <HelmetProvider>
      <div className="App" style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        height: '100vh',
        maxHeight: '-webkit-fill-available',
        backgroundColor: '#1a1a1a',
        color: '#ffffff',
        boxSizing: 'border-box',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}>
        <Helmet>
          <title>Manhole Cover Tinder | Discover Unique Covers</title>
          <meta name="description" content="Explore and rate unique manhole covers from around the world in this Tinder-style app. Swipe through a global collection of urban art beneath your feet!" />
          
          {/* OpenGraph metadata */}
          <meta property="og:title" content="Manhole Cover Tinder | Discover Unique Covers" />
          <meta property="og:description" content="Explore and rate unique manhole covers from around the world in this Tinder-style app." />
          <meta property="og:image" content="https://manholecover.xyz/images/og-image.jpg" />
          <meta property="og:url" content="https://manholecover.xyz" />
          <meta property="og:type" content="website" />

          {/* Twitter Card metadata */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@YourTwitterHandle" />
          <meta name="twitter:title" content="Manhole Cover Tinder | Discover Unique Covers" />
          <meta name="twitter:description" content="Explore and rate unique manhole covers from around the world in this Tinder-style app." />
          <meta name="twitter:image" content="https://manholecover.xyz/images/og-image.jpg" />
        </Helmet>
        
        <header style={{ 
          width: '100%', 
          maxWidth: '400px', 
          padding: '0.5rem', 
          boxSizing: 'border-box', 
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <img 
            src="./images/logo.png" 
            alt="Manhole Cover Tinder Logo" 
            style={{ height: '40px', width: 'auto' }}
          />
          <button
            onClick={() => setShowLeaderboard(true)}
            style={{
              padding: '0.6rem 0.7rem',
              backgroundColor: 'white',
              color: '#1a1a1a',
              border: 'white 1px solid',
              borderRadius: '0.55rem',
              cursor: 'pointer',
              fontSize: '0.85rem',
              display: 'flex',
              alignItems: 'center',
              gap: '0.3rem'
            }}
          >
            <Trophy size={16} />
            Leaderboard
          </button>
        </header>

        <main style={{ 
          width: '100%', 
          maxWidth: '400px', 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          padding: '0.5rem',
          boxSizing: 'border-box',
          overflow: 'hidden'
        }}>
          {showLeaderboard ? (
            <LeaderboardTable />
          ) : currentIndex < randomizedCovers.length ? (
            <motion.div 
              style={{ 
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                position: 'relative', 
                borderRadius: '0.75rem', 
                overflow: 'hidden', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                touchAction: 'pan-y'
              }}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              <AnimatePresence>
                <motion.div
                  key={currentIndex}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                  }}
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ 
                    opacity: 0, 
                    x: swipeDirection === 'left' ? -300 : swipeDirection === 'right' ? 300 : 0
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <motion.div
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    animate={showPrompt && currentIndex === 0 ? { rotate: [0, -2, 2, -2, 0] } : {}}
                    transition={{ duration: 0.5, repeat: showPrompt && currentIndex === 0 ? Infinity : 0, repeatDelay: 1 }}
                  >
                    {currentManhole && (
                      <img
                        src={currentManhole.image}
                        alt={currentManhole.location}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    )}
                    {showPrompt && currentIndex === 0 && (
                      <div style={{
                        position: 'absolute',
                        bottom: '64px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        textAlign: 'center',
                        width: '80%',
                        zIndex: 10
                      }}>
                        Swipe left to dislike, swipe right to like or use arrow keys on desktop
                      </div>
                    )}
                  </motion.div>
                </motion.div>
              </AnimatePresence>
              {currentManhole && (
                <motion.button
                  onClick={() => openMap(currentManhole)}
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    right: 0, 
                    padding: '1rem', 
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
                    border: 'none',
                    cursor: 'pointer',
                    textAlign: 'left',
                    width: '100%'
                  }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <p style={{ color: 'white', fontSize: '1.25rem', fontWeight: '600', margin: 0, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <MapPin size={20} />
                    {currentManhole.location}
                  </p>
                </motion.button>
              )}
            </motion.div>
          ) : allViewedMessage}
        </main>

        <footer style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          width: '100%', 
          maxWidth: '400px', 
          padding: '0.5rem',
          boxSizing: 'border-box',
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10
        }}>
          <motion.button 
            style={{ ...buttonStyle, backgroundColor: '#D55587', borderRadius: '0.75rem 0 0 0.75rem' }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleSwipe('left')}
            disabled={currentIndex >= randomizedCovers.length || showLeaderboard}
          >
            <ThumbsDown size={20} />
            <span>{disliked.length}</span>
          </motion.button>
          <motion.button 
            style={{ ...buttonStyle, backgroundColor: '#58B07B', borderRadius: '0 0.75rem 0.75rem 0' }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleSwipe('right')}
            disabled={currentIndex >= randomizedCovers.length || showLeaderboard}
          >
            <ThumbsUp size={20} />
            <span>{liked.length}</span>
          </motion.button>
        </footer>

        {(showLeaderboard || showMap) && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100
          }}>
            <div style={{
              width: '90%',
              height: '90%',
              backgroundColor: '#2a2a2a',
              borderRadius: '0.75rem',
              overflow: 'hidden',
              position: 'relative'
            }}>
              <button 
                onClick={() => {
                  setShowLeaderboard(false);
                  setShowMap(false);
                }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  zIndex: 10
                }}
              >
                <X size={20} />
              </button>
              {showLeaderboard && <LeaderboardTable />}
            </div>
          </div>
        )}

        {showMap && selectedManhole && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 110
          }}>
            <div style={{
              width: '90%',
              height: '90%',
              backgroundColor: 'white',
              borderRadius: '0.75rem',
              overflow: 'hidden',
              position: 'relative'
            }}>
              <button 
                onClick={closeMap}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  zIndex: 10
                }}
              >
                <X size={20} />
              </button>
              <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${convertCoordinates(selectedManhole.latitude, selectedManhole.longitude).lat},${convertCoordinates(selectedManhole.latitude, selectedManhole.longitude).lon}&zoom=15`}
                allowFullScreen
                title={`Map of ${selectedManhole.location}`}
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </HelmetProvider>
  );
}

export default App;